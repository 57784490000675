// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA_00uWPA-zNnpkGeXHkhjKHpKWwQA2r7c",
  authDomain: "ecommercedbseo6clone.firebaseapp.com",
  projectId: "ecommercedbseo6clone",
  storageBucket: "ecommercedbseo6clone.appspot.com",
  messagingSenderId: "591716014396",
  appId: "1:591716014396:web:42f1bcd4c2608ca5876639"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;